﻿//Theme white
$theme-white: #fff;
$theme-white-active-color: rgb(36, 35, 35);
$theme-white-sidemenu-active-color: #ffffff;
$theme-white-legal-bg: #2c303b;
$theme-white-bar: #2c303b;
$theme-white-navbar-brand: #2c303b;
$theme-white-navbar-brand_active: #2c303b;
$theme-white-navbar-brand_hover: #2c303b;
$theme-white-navbar-brand_focus: #2c303b;
$theme-white-nav-anchor: #3a2c70;
$theme-white-nav-anchor_hover: rgba(0, 0, 0, 0);
$theme-white-nav-anchor_focus: rgba(0, 0, 0, 0);
$theme-white-nav-anchor-opened: rgba(0, 0, 0, 0);
$theme-white-nav-anchor-opened_hover: rgba(0, 0, 0, 0);
$theme-white-nav-anchor-opened_focus: rgba(0, 0, 0, 0);
$theme-white-menu-list-active: rgba(0, 0, 0, 0);
$theme-white-menu-list-toggled: rgba(0, 0, 0, 0);
$theme-white-menu-list-submenu: rgba(0, 0, 0, 0);
$theme-white-sidebar-menu-active: #5783c7;
$theme-white-sidebar-menu-hover: #5783c7;

//Theme Black
$theme-black: #1a202e;
$theme-black-legal-bg: #fff;
$theme-black-bar: #fff;
$theme-black-button-color: #5783c7;
$theme-black-navbar-brand: #fff;
$theme-black-navbar-brand_active: #fff;
$theme-black-navbar-brand_hover: #fff;
$theme-black-navbar-brand_focus: #fff;
$theme-black-nav-anchor: #fff;
$theme-black-nav-anchor_hover: rgba(0, 0, 0, 0);
$theme-black-nav-anchor_focus: rgba(0, 0, 0, 0);
$theme-black-nav-anchor-opened: rgba(0, 0, 0, 0);
$theme-black-nav-anchor-opened_hover: rgba(0, 0, 0, 0);
$theme-black-nav-anchor-opened_focus: rgba(0, 0, 0, 0);
$theme-black-menu-list-active: rgba(0, 0, 0, 0);
$theme-black-menu-list-toggled: rgba(0, 0, 0, 0);
$theme-black-menu-list-submenu: rgba(0, 0, 0, 0);
$theme-black-sidebar-menu-active: #5783c7;
$theme-black-sidebar-menu-hover: #5783c7;

//Theme Purple
$theme-purple: #909de4;
$theme-purple-legal-bg: #fff;
$theme-purple-bar: #fff;
$theme-purple-navbar-brand: #fff;
$theme-purple-navbar-brand_active: #fff;
$theme-purple-navbar-brand_hover: #fff;
$theme-purple-navbar-brand_focus: #fff;
$theme-purple-nav-anchor: #fff;
$theme-purple-nav-anchor_hover: rgba(0, 0, 0, 0);
$theme-purple-nav-anchor_focus: rgba(0, 0, 0, 0);
$theme-purple-nav-anchor-opened: rgba(0, 0, 0, 0);
$theme-purple-nav-anchor-opened_hover: rgba(0, 0, 0, 0);
$theme-purple-nav-anchor-opened_focus: rgba(0, 0, 0, 0);
$theme-purple-menu-list-active: rgba(0, 0, 0, 0);
$theme-purple-menu-list-toggled: rgba(0, 0, 0, 0);
$theme-purple-menu-list-submenu: rgba(0, 0, 0, 0);

//Theme Blue
$theme-blue: #03a9f3;
$theme-blue-legal-bg: #fff;
$theme-blue-bar: #fff;
$theme-blue-navbar-brand: #fff;
$theme-blue-navbar-brand_active: #fff;
$theme-blue-navbar-brand_hover: #fff;
$theme-blue-navbar-brand_focus: #fff;
$theme-blue-nav-anchor: #fff;
$theme-blue-nav-anchor_hover: rgba(0, 0, 0, 0);
$theme-blue-nav-anchor_focus: rgba(0, 0, 0, 0);
$theme-blue-nav-anchor-opened: rgba(0, 0, 0, 0);
$theme-blue-nav-anchor-opened_hover: rgba(0, 0, 0, 0);
$theme-blue-nav-anchor-opened_focus: rgba(0, 0, 0, 0);
$theme-blue-menu-list-active: rgba(0, 0, 0, 0);
$theme-blue-menu-list-toggled: rgba(0, 0, 0, 0);
$theme-blue-menu-list-submenu: rgba(0, 0, 0, 0);

//Theme Cyan
$theme-cyan: #01d8da;
$theme-cyan-legal-bg: #fff;
$theme-cyan-bar: #fff;
$theme-cyan-navbar-brand: #fff;
$theme-cyan-navbar-brand_active: #fff;
$theme-cyan-navbar-brand_hover: #fff;
$theme-cyan-navbar-brand_focus: #fff;
$theme-cyan-nav-anchor: #fff;
$theme-cyan-nav-anchor_hover: rgba(0, 0, 0, 0);
$theme-cyan-nav-anchor_focus: rgba(0, 0, 0, 0);
$theme-cyan-nav-anchor-opened: rgba(0, 0, 0, 0);
$theme-cyan-nav-anchor-opened_hover: rgba(0, 0, 0, 0);
$theme-cyan-nav-anchor-opened_focus: rgba(0, 0, 0, 0);
$theme-cyan-menu-list-active: rgba(0, 0, 0, 0);
$theme-cyan-menu-list-toggled: rgba(0, 0, 0, 0);
$theme-cyan-menu-list-submenu: rgba(0, 0, 0, 0);

//Theme Green
$theme-green: #11a37d;
$theme-green-legal-bg: #fff;
$theme-green-bar: #fff;
$theme-green-navbar-brand: #fff;
$theme-green-navbar-brand_active: #fff;
$theme-green-navbar-brand_hover: #fff;
$theme-green-navbar-brand_focus: #fff;
$theme-green-nav-anchor: #fff;
$theme-green-nav-anchor_hover: rgba(0, 0, 0, 0);
$theme-green-nav-anchor_focus: rgba(0, 0, 0, 0);
$theme-green-nav-anchor-opened: rgba(0, 0, 0, 0);
$theme-green-nav-anchor-opened_hover: rgba(0, 0, 0, 0);
$theme-green-nav-anchor-opened_focus: rgba(0, 0, 0, 0);
$theme-green-menu-list-active: rgba(0, 0, 0, 0);
$theme-green-menu-list-toggled: rgba(0, 0, 0, 0);
$theme-green-menu-list-submenu: rgba(0, 0, 0, 0);

//Theme Orange
$theme-orange: #f16735;
$theme-orange-legal-bg: #fff;
$theme-orange-bar: #fff;
$theme-orange-navbar-brand: #fff;
$theme-orange-navbar-brand_active: #fff;
$theme-orange-navbar-brand_hover: #fff;
$theme-orange-navbar-brand_focus: #fff;
$theme-orange-nav-anchor: #fff;
$theme-orange-nav-anchor_hover: rgba(0, 0, 0, 0);
$theme-orange-nav-anchor_focus: rgba(0, 0, 0, 0);
$theme-orange-nav-anchor-opened: rgba(0, 0, 0, 0);
$theme-orange-nav-anchor-opened_hover: rgba(0, 0, 0, 0);
$theme-orange-nav-anchor-opened_focus: rgba(0, 0, 0, 0);
$theme-orange-menu-list-active: rgba(0, 0, 0, 0);
$theme-orange-menu-list-toggled: rgba(0, 0, 0, 0);
$theme-orange-menu-list-submenu: rgba(0, 0, 0, 0);
